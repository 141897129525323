<template>
  <section id="shop">
    <b-row class="mb-5">
      <b-col v-if="!isFinal" md="8" class="pb-3 pb-md-0">
        <h1>{{ $t("category.shopCat") }}</h1>
        <h5 class="text-primary">
          {{ $t("category.shopPlatf") }}
        </h5>
      </b-col>
      <b-col v-else md="8" class="pb-3 pb-md-0">
        <h1>{{ $t("category.shopProd") }}</h1>
        <h5 class="text-primary">
          {{ $t("category.shopPlatf") }}
        </h5>
      </b-col>
    </b-row>
    <category-showroom :code="code" @final="final" />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import categoryShowroom from './CategoryShowroom.vue'

export default {
  components: {
    categoryShowroom,
    BRow,
    BCol,
  },

  data() {
    return {
      code: this.$route.params.code,
      isFinal: false,
    }
  },

  methods: {
    final(data) {
      this.isFinal = data
    },
  },
}
</script>
